import React, { Component } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import MoreButton from "../components/more-button"
import Link  from '../components/delayed-gatsby-link';

class Work extends Component {
  constructor(props) {
    super(props);
    this._itemClicked = this.itemClicked.bind(this);
  }

  itemClicked(event){
    alert('animate out');
    window.dispatchEvent( new Event('animateOut') );
  }

  render() {
    const data = this.props.data

    data.allWordpressWpProjects.edges.sort(function(a, b) {
        return a.node.menu_order - b.node.menu_order;
    });

    return (
      <div className="page Work ColorKey" data-bgcolor="#ffffff">
        <Helmet
          title={'Work - illcreative'}
          meta={[
            { name: 'description', content: 'Our latest + greatest. We design digital products, services, and eCommerce experiences for brands like OLLY, Welly, dpHUE, Estée Lauder, North Spore and other category leaders.'}
          ]}
        />
        <div className="main-content">
          <div className="main-wrap">
            {data.allWordpressWpProjects.edges.map(({ node }) => (
              <section className={'ProjectPreview no-padding-top no-padding-bottom no-padding-left no-padding-right fader ' +node.slug} key={node.slug} data-bgcolor={node.acf.primary_color}>
                <Link delay={1000} className="inner taphover" to={'/work/'+node.slug} onClick={this._itemClicked} style={{ backgroundColor: node.acf.primary_color }}>
                  <div className="title-block">
                    <div className="text-wrap">
                      <h3 className="eyebrow fader">{node.title}</h3>
                      <h2 className="eyebrow-before fader" dangerouslySetInnerHTML={{__html: node.acf.title }}></h2>
                      <MoreButton/>
                    </div>
                  </div>
                  
                  <div className="image-block">
                    <img className="image desktop" src={ node.acf.preview_image.source_url } alt={node.acf.preview_image.alt_text}/>
                    <img className="image mobile" src={node.acf.preview_image.source_url.replace('.png' , '-768x768.png')} alt={node.acf.preview_image.alt_text}/>
                  </div>
                </Link>
              </section>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default Work

export const pageQuery = graphql`
  query {
    allWordpressWpProjects {
      edges {
        node {
          title
          slug
          menu_order
          acf {
            primary_color
            title
            preview_image {
              source_url
              alt_text
            }
          }
        }
      }
    }
  }
`